import client from './index'

import { ApiResponse, PaginationMeta, PaginationParams } from '@/models/api'
import {
  TransactionLog,
  UpdateUserWallet,
  UserBalance,
  WalletLog,
} from '@/models/finance'

export const getUserWalletLogs = (
  userId: string,
  pagination: PaginationParams
) => {
  return client.get<ApiResponse<WalletLog[], PaginationMeta>>(
    `/users/${userId}/transactions`,
    {
      params: pagination,
    }
  )
}

export const getUserBalance = (userId: string | number) => {
  return client.get<ApiResponse<UserBalance>>(`/users/${userId}/user-wallet`)
}

export const getTransactionLog = (transactionId: string) => {
  return client.get<ApiResponse<TransactionLog[]>>('/transaction-logs', {
    params: {
      transaction_id: transactionId,
    },
  })
}

export const updateUserBalance = (
  userId: string | number,
  data: UpdateUserWallet
) => {
  return client.put(`/users/${userId}/user-wallet`, data)
}
